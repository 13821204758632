import React, { useEffect, useState } from "react";
import Previous from "../../components/Previous";
import { listCard } from "../../services/dataFake";
import Button from "../../components/Button";
import CompareCard from "../../components/compare/CompareCard";
import CardDescription from "../../components/CardDescription";
import useMyContext from "../../hooks/useMyContext";
import { useLocation, useNavigate } from "react-router-dom";
import SelectCardCompareModal from "../../components/modals/SelectCardCompareModal";
import { cardServices } from "../../services/cardService";
import { Helmet } from "react-helmet";

const CardCompare = () => {
  const location = useLocation();
  const {
    setListCompare,
    isOpenModalSelectCardCompare,
    listCompare,
    cardListAll,
    setCardListAll,
  } = useMyContext();
  const [cardDetail1, serCardDetail1] = useState({});
  const [cardDetail2, serCardDetail2] = useState({});
  const [cardDetail3, serCardDetail3] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState();

  useEffect(() => {
    setData(cardListAll);
  }, [cardListAll]);
  useEffect(() => {
    listCompare.length === 0 && navigate("/");
    console.log("listCompare: ", listCompare);
  }, []);
  useEffect(() => {
    setListCompare(listCompare);
    listCompare[0] &&
      cardServices
        .getCardDetail(listCompare[0]?.be_id)
        .then((res) => {
          serCardDetail1(res.data.result.data.credit_card);
        })
        .catch((err) => {
          console.log(err);
        });
    listCompare[1] &&
      cardServices
        .getCardDetail(listCompare[1]?.be_id)
        .then((res) => {
          serCardDetail2(res.data.result.data.credit_card);
        })
        .catch((err) => {
          console.log(err);
        });
    listCompare[2] &&
      cardServices
        .getCardDetail(listCompare[2]?.be_id)
        .then((res) => {
          serCardDetail3(res.data.result.data.credit_card);
        })
        .catch((err) => {
          console.log(err);
        });
    listCompare.length === 2 && serCardDetail3({});
  }, [listCompare]);
  const handleDeleteCardCompare = (index, listCompare) => {
    const arr = JSON.parse(JSON.stringify(listCompare));
    arr.splice(index, 1);
    setListCompare(arr);
  };

  return (
    <>
      <Helmet>
        <title>Credit Card: So sánh thẻ</title>
      </Helmet>
      <Previous />
      <div
        className="page-compare rounded-xl mb-6"
        style={{
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
          background: "rgba(39, 50, 47, 0.40)",
        }}
      >
        <div className="grid-container overflow-scroll sm:overflow-auto text-white">
          {listCompare.map((item, index) => (
            <div className="grid-item" key={index}>
              <CompareCard
                data={item}
                index={index}
                handleDeleteCardCompare={handleDeleteCardCompare}
              />
            </div>
          ))}
          {listCompare.length == 2 && (
            <div className="grid-item">
              <CompareCard />
            </div>
          )}

          {/* HẠN MỨC THẺ */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold ">
              HẠN MỨC THẺ
            </h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div className="grid-item py-3 px-4">
            <p>{cardDetail1?.limit}</p>
          </div>
          <div className="grid-item py-3 px-4">
            <p>{cardDetail2?.limit}</p>
          </div>
          <div className="grid-item py-3 px-4">
            <p>{cardDetail3?.limit}</p>
          </div>

          {/* KHUYẾN MÃI */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold">Khuyến mãi</h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail1?.promotion?.inuse_promotion} />
          </div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail2?.promotion?.inuse_promotion} />
          </div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail3?.promotion?.inuse_promotion} />
          </div>

          {/* THỜI GIAN MIỄN LÃI */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold">
              THỜI GIAN MIỄN LÃI
            </h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>

          <div className="grid-item px-4 py-3">
            <p>{cardDetail1?.free_fee_duration}</p>
          </div>
          <div className="grid-item px-4 py-3">
            <p>{cardDetail2?.free_fee_duration}</p>
          </div>
          <div className="grid-item px-4 py-3">
            <p>{cardDetail3?.free_fee_duration}</p>
          </div>

          {/* Đặc điểm nổi bật */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold">
              Đặc điểm nổi bật
            </h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>

          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail1?.special_features} />
          </div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail2?.special_features} />
          </div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail3?.special_features} />
          </div>

          {/* Phí thường niên */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold">
              Phí thường niên
            </h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>

          <div className="grid-item px-4 py-3">
            <p>{cardDetail1?.annual_fee}</p>
          </div>
          <div className="grid-item px-4 py-3">
            <p>{cardDetail2?.annual_fee}</p>
          </div>
          <div className="grid-item px-4 py-3">
            <p>{cardDetail3?.annual_fee}</p>
          </div>

          {/* Lãi suất */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold">Lãi suất</h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>

          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail1?.interest} />
          </div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail2?.interest} />
          </div>
          <div className="grid-item px-4 py-3">
            <CardDescription data={cardDetail3?.interest} />
          </div>

          {/* Phí khác (Phí trả chậm) */}
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>
          <div
            className="grid-item h-7 flex items-center justify-center"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          >
            <h6 className="uppercase text-[12px] font-semibold">
              Phí khác (Phí trả chậm)
            </h6>
          </div>
          <div
            className="grid-item h-7"
            style={{ background: "rgba(171, 171, 171, 0.20)" }}
          ></div>

          <div className="grid-item px-4 py-3">
            <p>{cardDetail1?.another_fee}</p>
          </div>
          <div className="grid-item px-4 py-3">
            <p>{cardDetail2?.another_fee}</p>
          </div>
          <div className="grid-item px-4 py-3">
            <p>{cardDetail3?.another_fee}</p>
          </div>
        </div>
        {isOpenModalSelectCardCompare && (
          <SelectCardCompareModal
            setListCompare={setListCompare}
            data={cardListAll}
            listCompare={listCompare}
          />
        )}
      </div>
    </>
  );
};

export default CardCompare;
