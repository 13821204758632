import { axiosInstance } from "./configUrl";

export const cardServices = {
  getCardList: () => {
    return axiosInstance.post("/get_credit_card_list", {});
  },
  getCardDetail: (cardId) => {
    return axiosInstance.post("/get_credit_card_details", {
      params: {
        be_id: cardId,
      },
    });
  },
  registerCard: (data) => {
    return axiosInstance.post("/credit_card_register", { params: data });
  },
  registerCTV: (data) => {
    return axiosInstance.post("/ctv_register", { params: data });
  },
};
