import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useMyContext from "../../hooks/useMyContext";
import InputField from "../InputField";
import { Field, Form, Formik } from "formik";
import Button from "../Button";
import { listCard } from "../../services/dataFake";

export default function SelectCardCompareModal({
  setListCompare,
  data,
  listCompare,
}) {
  const {
    isOpenModalSelectCardCompare,
    setOpenModalSelectCardCompare,
    cardIndexDelete,
  } = useMyContext();
  const [cardSelect, setCardSelect] = useState(listCard[0].id);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 430,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
  };

  const onClose = () => {
    setOpenModalSelectCardCompare(false);
  };
  const handleSubmit = () => {
    const cardNew = data.filter((item) => item.be_id === cardSelect)[0];

    if (cardIndexDelete != null) {
      const arr = [...listCompare];
      arr[cardIndexDelete] = cardNew;

      setListCompare(arr);
    } else {
      setListCompare((prevState) => [...prevState, cardNew]);
    }
    onClose();
  };

  return (
    <Modal
      open={isOpenModalSelectCardCompare}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="px-[40px] sm:px-[24px] py-[24px]">
          <h3 className="mt-[8px] text-[16px] md:text-[24px] font-semibold text-center text-[#1E1E1E]">
            Chọn thẻ để so sánh
          </h3>

          {data.map((card, index) => (
            <div
              className="hover:!border-[#4597a9] hover:cursor-pointer relative mt-2 w-[380px] h-[56px] rounded p-[10px] flex items-center"
              key={index}
              style={{
                border: `1px solid ${
                  cardSelect == card.be_id ? "#3CB371" : "#E5E5E5"
                }`,
                background: ` ${
                  cardSelect == card.id
                    ? "rgba(60, 179, 113, 0.10)"
                    : "rgba(255, 255, 255, 0.10)"
                }`,
              }}
              onClick={() => setCardSelect(card.be_id)}
            >
              <div className="flex justify-center items-center w-[58px] h-[46px]">
                <img
                  src={card.front_image}
                  alt="card"
                  className="max-w-[100%] max-h-[46px]"
                />
              </div>
              <p className="ml-4 text-[15px] font-medium text-[#1E1E1E]">
                {card.name}
              </p>
              {cardSelect == card.be_id && (
                <div className="absolute top-3 right-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M16.0001 29.3333C8.63628 29.3333 2.66675 23.3637 2.66675 16C2.66675 8.63621 8.63628 2.66667 16.0001 2.66667C23.3638 2.66667 29.3334 8.63621 29.3334 16C29.3334 23.3637 23.3638 29.3333 16.0001 29.3333ZM14.6702 21.3333L24.0983 11.9052L22.2127 10.0196L14.6702 17.5621L10.899 13.7908L9.0134 15.6765L14.6702 21.3333Z"
                      fill="#3CB371"
                    />
                  </svg>
                </div>
              )}
            </div>
          ))}

          <div className="flex justify-center mt-4">
            <Button
              onClick={handleSubmit}
              textSize="16px"
              className="mt-1 w-[140px] sm:w-[180px]"
              backgroundColor="#3CB371"
              color="#fff"
            >
              Xong
            </Button>
          </div>
          <button
            className="absolute top-2 right-6 sm:right-2"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                fill="#979797"
              />
            </svg>
          </button>
        </div>
      </Box>
    </Modal>
  );
}
