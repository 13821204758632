import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='' style={{ borderTop: '1px solid #3CB371' }}>
            <div className='mt-[23px] flex flex-col sm:flex-row gap-8 justify-between items-center'>
                <div>
                    <h5 className="flex items-center gap-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M0 19.2V4.8C0 2.14903 2.14903 0 4.8 0H19.2L24 9.53674e-07V4.8V19.2C24 21.851 21.851 24 19.2 24H4.8H0V19.2Z" fill="#3CB371" />
                        </svg>
                        <p className="text-[20px] font-bold leading-6 tracking-[-0.6px] text-white">CreditCardVN</p>
                    </h5>
                    <p className='mt-[10px]'>Số 19 Đông Hưng Thuận 42,</p>
                    <p>P. Tân Hưng Thuận, Q.12, TP.HCM</p>
                </div>
                <div className='flex w-[100%] sm:w-[40%] justify-evenly'>
                    <div>
                        <h6 className='text-[14px] font-semibold uppercase text-white'>Liên hệ</h6>
                        <ul className='mt-4'>
                            <li className='mt-2'>
                                <Link to={'/'} className='font-normal'>Chăm sóc khách hàng</Link>
                            </li>
                            <li className='mt-2'>
                                <Link to={'/'} className='font-normal'>Hotline</Link>
                            </li>
                            <li className='mt-2'>
                                <Link to={'/'} className='font-normal'>Website</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h6 className='text-[14px] font-semibold uppercase text-white'>Mạng xã hội</h6>
                        <ul className='mt-4'>
                            <li className='mt-2'>
                                <Link to={'/'} className='font-normal'>Facebook</Link>
                            </li>
                            <li className='mt-2'>
                                <Link to={'/'} className='font-normal'>LinkedIn</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
