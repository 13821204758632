import React from "react";
import Button from "../Button";
import useMyContext from "../../hooks/useMyContext";
import { listCard } from "../../services/dataFake";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
export default function CompareTab() {
  const { listCompare, setOpenModalCompareTab, setListCompare } =
    useMyContext();
  const navigate = useNavigate();

  return (
    <div className="h-[130px] sm:h-[100px]">
      <div
        className="fixed bottom-0 h-[130px] sm:h-[100px] w-full px-[30px] lg:px-[100px] flex items-center justify-between flex-col sm:flex-row bg-white"
        style={{ boxShadow: "0px -8px 20px 0px rgba(0, 0, 0, 0.15)" }}
      >
        <div className="flex items-center flex-col sm:flex-row justify-center sm:gap-[40px] mb-2 sm:mb-0">
          <p className="text-[12px] md:text-[16px] font-normal text-[#1E1E1E]">
            Bạn có thể chọn tối đa 3 thẻ để so sánh
          </p>
          <div className="flex gap-[24px] mr-2">
            <div className="w-[88px] h-[56px] relative">
              <img
                className="max-w-[88px] max-h-[56px]"
                src={listCompare[0]?.front_image}
                alt="test"
              />
              <div
                className="flex justify-center items-center mb-2 absolute right-[-16%] -top-[26%]"
                onClick={() => {
                  const result = listCompare.filter(
                    (c) => c.be_id != listCompare[0]?.be_id
                  );

                  setListCompare(result);
                }}
              >
                <CloseIcon className="cursor-pointer" />
              </div>
            </div>
            <div
              className="w-[88px] h-[56px] justify-center items-center rounded bg-white"
              style={{
                border: "1px dashed #ADB2B1",
                display: listCompare[1] ? "" : "flex",
              }}
            >
              {listCompare[1] ? (
                <div className="relative">
                  <img
                    className="max-w-[88px] max-h-[56px]"
                    src={listCompare[1].front_image}
                    alt="test"
                  />
                  <div
                    className="flex justify-center items-center cursor-pointer absolute right-[-19%] -top-[26%]"
                    onClick={() => {
                      const result = listCompare.filter(
                        (c) => c.be_id != listCompare[1]?.be_id
                      );

                      setListCompare(result);
                    }}
                  >
                    <CloseIcon className="cursor-pointer" />
                  </div>
                </div>
              ) : (
                <p className="text-[#ADB2B1] text-[24px]">2</p>
              )}
            </div>
            <div
              className="w-[88px] h-[56px] justify-center items-center rounded bg-white"
              style={{
                border: "1px dashed #ADB2B1",
                display: listCompare[2] ? "" : "flex",
              }}
            >
              {listCompare[2] ? (
                <div className="relative">
                  <img
                    className="max-w-[88px] max-h-[56px]"
                    src={listCompare[2].front_image}
                    alt="test"
                  />
                  <div
                    className="flex justify-center items-center cursor-pointer absolute right-[-19%] -top-[26%]"
                    onClick={() => {
                      const result = listCompare.filter(
                        (c) => c.be_id != listCompare[2]?.be_id
                      );

                      setListCompare(result);
                    }}
                  >
                    <CloseIcon className="cursor-pointer" />
                  </div>
                </div>
              ) : (
                <p className="text-[#ADB2B1] text-[24px]">3</p>
              )}
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              setOpenModalCompareTab(false);
              navigate("/compare", { state: listCompare });
            }}
            className="w-[100px] md:w-[180px]"
            disabled={listCompare.length < 2}
            backgroundColor={listCompare.length < 2 ? "#979797" : "#3CB371"}
            textSize="16px"
          >
            So sánh
          </Button>
        </div>
      </div>
    </div>
  );
}
