import React from 'react'

export default function Button({ children, backgroundColor = '#3CB371', color = '', className = '',
    type = 'button', onClick, textSize = '14px', padding, disabled = false }) {

    return (
        <button type={type} style={{ background: backgroundColor, padding: padding ? padding : '' }}
            className={`flex items-center justify-center px-[12px] py-[4px] sm:px-[24px] sm:py-[8px] lg:px-[32px] lg:py-[12px] gap-[10px] rounded-[64px] ${disabled ? '' : 'hover:opacity-80'}  ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            <span style={{ color: color ? color : '#fff' }} className={`text-white font-bold leading-6 text-[8px] sm:text-[12px] md:text-[14px] lg:text-[$${textSize}]`}>{children}</span>
        </button>
    )
}
