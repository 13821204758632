import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../Button";
import useMyContext from "../../hooks/useMyContext";
import { IconButton } from "@mui/material";
import IconMenu from "../IconMenu";

export default function Header() {
  const { setOpenModalRegister, setListCompare, setOpenModalCompareTab } =
    useMyContext();
  const [toggle, setToggle] = useState();
  const navMobileRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const id = location.state?.id;
    if (id) {
      const targetSection = document.getElementById(id);
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    if (location.pathname != "/") {
      setOpenModalCompareTab(false);
      setListCompare([]);
    }
  }, [location.pathname]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navMobileRef.current &&
        !navMobileRef.current.contains(event.target)
      ) {
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className=" w-full flex items-center justify-between">
        <Link to="/" className="flex items-center justify-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M0 19.2V4.8C0 2.14903 2.14903 0 4.8 0H19.2L24 9.53674e-07V4.8V19.2C24 21.851 21.851 24 19.2 24H4.8H0V19.2Z"
              fill="#3CB371"
            />
          </svg>
          <h5 className="text-white text-[12px] md:text-[20px] font-bold leading-6 tracking-[-0.6px]">
            CreditCardVN
          </h5>
        </Link>
        <div className="">
          {/* className="hidden lg:block" */}
          <ul
            className={` items-center justify-center gap-[40px] hidden lg:flex `}
          >
            <li>
              <p
                onClick={() => {
                  const targetSection = document.getElementById("card-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "card-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Thẻ
              </p>
            </li>
            <li>
              <p
                onClick={() => {
                  const targetSection =
                    document.getElementById("utilities-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "utilities-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Tiện ích
              </p>
            </li>
            <li>
              <p
                onClick={() => {
                  const targetSection = document.getElementById("form-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "form-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Đăng ký CTV
              </p>
            </li>
            <li>
              <p
                onClick={() => {
                  const targetSection = document.getElementById("contact-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "contact-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Liên hệ
              </p>
            </li>
          </ul>

          <ul
            ref={navMobileRef}
            className={`pt-5 items-center justify-center nav-mobile flex lg:hidden`}
            style={{ left: toggle ? "0" : "" }}
          >
            <li>
              <p
                onClick={() => {
                  const targetSection = document.getElementById("card-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "card-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Thẻ
              </p>
            </li>
            <li>
              <p
                onClick={() => {
                  const targetSection =
                    document.getElementById("utilities-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "utilities-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Tiện ích
              </p>
            </li>
            <li>
              <p
                onClick={() => {
                  const targetSection = document.getElementById("form-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "form-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Đăng ký CTV
              </p>
            </li>
            <li>
              <p
                onClick={() => {
                  const targetSection = document.getElementById("contact-link");
                  if (!targetSection) {
                    navigate("/", { state: { id: "contact-link" } });
                  }
                  targetSection?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-[16px] leading-normal font-medium"
              >
                Liên hệ
              </p>
            </li>
          </ul>
          {/* overlay */}
          {toggle && (
            <div className="fixed top-0 right-0 w-full h-full z-10 bg-slate-50 opacity-30"></div>
          )}

          {/* <div className='lg:!hidden '
                        style={{ display: toggle ? 'block' : '' }}
                    >
                    </div> */}
        </div>

        <div className="flex md:order-2 justify-between items-center">
          <Button
            className="w-[100px] sm:w-auto h-[30px] sm:h-auto md:text-[16px]"
            textSize="16px"
            onClick={() => setOpenModalRegister(true)}
          >
            Mở thẻ tín dụng
          </Button>
          {/* <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        button
                    </button> */}
          <div
            onClick={() => setToggle(!toggle)}
            className="flex items-center ml-2 sm:ml-8 lg:hidden"
          >
            <IconMenu isOpenMenuMobile={toggle} />
          </div>
        </div>
      </nav>
    </>
  );
}
