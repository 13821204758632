import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useMyContext from "../../hooks/useMyContext";
import InputField from "../InputField";
import { Field, Form, Formik, getIn } from "formik";
import Button from "../Button";
import SelectField from "../SelectField";
import * as Yup from "yup";
import axios from "axios";
import { provinceService } from "../../services/provinceService";
import { cardServices } from "../../services/cardService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const schema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập trường này"),
  id_card: Yup.string()
    .test("is-number", "Vui lòng nhập số", (value) => !isNaN(value))
    .min(9, "CMND/CCCD tối thiểu 9 số")
    .max(12, "CMND/CCCD tối đa 12 số")
    .required("Vui lòng nhập trường này"),
  email: Yup.string()
    .email("Email không hợp lệ")
    .required("Vui lòng nhập trường này"),
  city_code: Yup.string().required("Vui lòng chọn trường này"),
  district_code: Yup.string().required("Vui lòng chọn trường này"),
  income: Yup.string().required("Vui lòng nhập trường này"),
  phone: Yup.string().required("Vui lòng nhập trường này"),
});

export default function RegisterModal() {
  const { isOpenModalRegister, setOpenModalRegister, setOpenModalSelectCard } =
    useMyContext();
  let navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [options, setOptions] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [provinceId, setProvinceId] = useState();
  const [cardList, setCardList] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 430,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
  };

  const onClose = () => {
    setOpenModalRegister(false);
  };
  const handleSubmit = (values) => {
    let windowReference = window.open();

    let finalData = { ...values, be_id: +values.be_id };
    cardServices
      .registerCard(finalData)
      .then((res) => {
        if (res.data.result.meta.status) {
          toast.success("Đăng kí thẻ thành công");

          windowReference.location = res.data.result.data.redirect_url;
          onClose();
        } else {
          if (typeof res.data.result.meta.message === "string") {
            return toast.error(res.data.result.meta.message);
          }
          const keys = Object.keys(res.data.result.meta.message);
          keys.forEach((key, index) => {
            toast.error(res.data.result.meta.message[key]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    provinceService
      .getProvinceList()
      .then((res) => {
        let cityList = res.data.result.data.city_list;
        setProvinceList(cityList);
        setOptions(
          cityList.map((item) => {
            return {
              label: item.name,
              value: item.code,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    cardServices
      .getCardList()
      .then((res) => {
        setCardList(
          res.data.result.data.credit_card_list.map((item) => {
            return {
              label: item.name,
              value: item.be_id,
            };
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSetValue = (value, setValue) => {
    setValue(value);
  };
  useEffect(() => {
    if (provinceId) {
      console.log("provinceId: ", provinceId);
      let districtList = provinceList.find((item) => +item.code == +provinceId);
      setDistrictList(
        districtList.district_list.map((item) => {
          return {
            label: item.name,
            value: item.code,
          };
        })
      );
      console.log("districtList: ", districtList);
    }
  }, [provinceId]);

  return (
    <Modal
      open={isOpenModalRegister}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="px-[40px] sm:px-[24px] py-[24px]">
          <h3 className="mt-[8px] text-[16px] md:text-[24px] font-semibold text-center text-[#1e1e1e]">
            Đăng ký mở thẻ tín dụng
          </h3>
          <Formik
            validationSchema={schema}
            initialValues={{
              name: "",
              id_card: "",
              email: "",
              city_code: "",
              district_code: "",
              income: "",
              be_id: "",
              phone: "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik) => {
              const { errors, touched } = formik;
              const isEmptyError =
                Object.keys(errors).length === 0 &&
                checked &&
                Object.keys(touched).length !== 0;
              return (
                <Form className="mt-[16px] flex flex-col gap-3">
                  <Field
                    name="name"
                    placeholder="Họ và tên"
                    className="input-form-modal"
                    component={InputField}
                    label="Họ và tên"
                  />
                  <Field
                    name="email"
                    placeholder="Email"
                    className="input-form-modal"
                    component={InputField}
                    label="Email"
                  />
                  <Field
                    name="phone"
                    placeholder="Số điện thoại"
                    className="input-form-modal"
                    component={InputField}
                    label="Số điện thoại"
                  />
                  <Field
                    name="id_card"
                    placeholder="Số CMND/CCCD"
                    className="input-form-modal"
                    component={InputField}
                    label="Số CMND/CCCD"
                  />
                  <Field
                    name="be_id"
                    className="input-form-modal"
                    listOption={cardList}
                    component={SelectField}
                    label="Thẻ"
                  />
                  <Field
                    name="city_code"
                    className="input-form-modal"
                    listOption={options}
                    component={SelectField}
                    label="Tỉnh/TP"
                    setvalue={handleSetValue}
                    value={setProvinceId}
                    id="77777"
                  />
                  <Field
                    name="district_code"
                    className="input-form-modal"
                    listOption={districtList}
                    component={SelectField}
                    label="Quận/huyện"
                  />
                  <Field
                    name="income"
                    placeholder="Mức thu nhập/tháng"
                    className="input-form-modal"
                    component={InputField}
                    label="Mức thu nhập/tháng"
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onClick={() => setChecked(!checked)}
                        />
                      }
                      label={
                        <label
                          onClick={() => setChecked(!checked)}
                          htmlFor="check"
                          className=""
                        >
                          Đồng ý các{" "}
                          <span>
                            <a
                              target="_blank"
                              href="https://www.vib.com.vn/vn/an-toan-bao-mat"
                              className="underline inline-block"
                            >
                              {" "}
                              điều khoản
                            </a>
                          </span>{" "}
                          sử dụng thông tin cá nhân
                        </label>
                      }
                    />
                  </FormGroup>

                  <div className="flex justify-center mt-4">
                    <Button
                      disabled={!isEmptyError}
                      className="mt-1 w-[140px] sm:w-[180px]"
                      backgroundColor={isEmptyError ? "#3CB371" : "#979797"}
                      color="#fff"
                      type="submit"
                    >
                      Đăng ký
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <button
            className="absolute top-2 right-6 sm:right-2"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                fill="#979797"
              />
            </svg>
          </button>
        </div>
      </Box>
    </Modal>
  );
}
