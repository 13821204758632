import React from "react";

export default function CardDescription({ data = [] }) {
  return (
    <>
      <ul
        className="font-normal ml-[20px] mt-1 text-left"
        style={{ listStyle: "disc" }}
      >
        {typeof data === "string" ? (
          <li>{data}</li>
        ) : (
          data.map((item, i) => (
            <>
              {typeof item == "string" ? (
                <li key={i}>{item}</li>
              ) : (
                item.map((item2, j) => (
                  <li className="ml-5" key={i.toString() + j.toString() + "n"}>
                    {item2}
                  </li>
                ))
              )}
            </>
          ))
        )}
      </ul>
    </>
  );
}
