import React from 'react'
import useMyContext from '../hooks/useMyContext'

export default function IconMenu({ isOpenMenuMobile }) {

    return (
        <div className={`icon-menu lg:hidden ${isOpenMenuMobile ? 'change' : ''}`}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>
    )
}
