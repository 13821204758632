import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { listCard } from "../../services/dataFake";
import Previous from "../../components/Previous";
import CardDescription from "../../components/CardDescription";
import useMyContext from "../../hooks/useMyContext";
import { useNavigate, useParams } from "react-router-dom";
import { cardServices } from "../../services/cardService";
import { Helmet } from "react-helmet";

const CardDetail = () => {
  const [cardDetail, serCardDetail] = useState({});
  let { id } = useParams();
  const navigate = useNavigate();

  const { setOpenModalRegister } = useMyContext();

  const handleRegister = () => {
    setOpenModalRegister(true);
  };

  useEffect(() => {
    cardServices
      .getCardDetail(id)
      .then((res) => {
        if (res.data.result.data.credit_card) {
          serCardDetail(res.data.result.data.credit_card);
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        navigate("/");
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Credit Card: Chi tiết thẻ </title>
      </Helmet>
      <Previous />
      <div
        className="page-detail rounded-xl p-[24px] mb-6 min-h-[70vh]
        åå"
        style={{
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
          background: "rgba(39, 50, 47, 0.40)",
        }}
      >
        <h3 className="text-[24px] font-bold text-white">
          {cardDetail?.title}
        </h3>
        <div className="flex flex-col sm:flex-row gap-[50px]">
          <div className="mt-4">
            <div
              className={`card-flip card-flip-${0}`}
              onClick={() => {
                const card = document.querySelector(`.card-flip-${0}`);
                card.classList.toggle("is-flipped");
              }}
            >
              <div class="card__face card__face--front">
                <div className="flex justify-center items-center">
                  <img src={cardDetail?.front_image} alt="card" />
                </div>
              </div>
              <div class="card__face card__face--back">
                <div className="flex justify-center items-center">
                  <img src={cardDetail?.back_image} alt="card" />
                </div>
              </div>

              <div className="flex justify-center items-center invisible">
                <img src={cardDetail?.front_image} alt="card" />
              </div>
            </div>
            <div className="flex justify-start sm:justify-center cards-center items-center">
              <Button
                onClick={handleRegister}
                className="mt-4 md:min-w-[150px]"
              >
                Đăng ký ngay
              </Button>
            </div>
          </div>
          <div className="">
            <div className="text-[14px] font-semibold leading-6 mt-0 sm:mt-[16px]">
              <div>
                <h6 className="uppercase text-white">TÍNH NĂNG NỔI BẬT</h6>
                <CardDescription data={cardDetail?.special_features} />
              </div>
              <div className="mt-[16px]">
                <h6 className="uppercase text-white">HẠN MỨC VÀ PHÍ</h6>
                <CardDescription data={cardDetail?.limit} />
                <CardDescription data={cardDetail?.annual_fee} />
              </div>
            </div>
            <div className="text-[14px] font-semibold leading-6 mt-[16px]">
              <h6 className="uppercase text-white">Lợi ích khi mở thẻ</h6>
              <CardDescription data={cardDetail?.promotion?.open_promotion} />
            </div>
            <div className="text-[14px] font-semibold leading-6 mt-[16px]">
              <h6 className="uppercase text-white">Lợi ích khi sử dụng thẻ</h6>
              <CardDescription data={cardDetail?.promotion?.inuse_promotion} />
            </div>
            <div className="text-[14px] font-semibold leading-6 mt-[16px]">
              <h6 className="uppercase text-white">Lợi ích cộng thêm</h6>
              <CardDescription data={cardDetail?.promotion?.bonus_promotion} />
            </div>
            <div className="text-[14px] font-semibold leading-6 mt-[16px]">
              <h6 className="uppercase text-white">ĐIỀU KIỆN HỒ SƠ</h6>
              <CardDescription data={cardDetail?.application} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetail;
