import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import { Link, useSearchParams } from "react-router-dom";
import { benefits } from "../../services/dataFake";
import Card from "../../components/Card";
import creditCart from "../../assets/images/credit-card.png";
import FormRegister from "../../components/FormRegister";
import personImg from "../../assets/images/person.png";
import CompareTab from "../../components/compare/CompareTab";
import { cardServices } from "../../services/cardService";
import useMyContext from "../../hooks/useMyContext";

const Home = () => {
  const [cardList, setCardList] = useState([]);
  const { cardListAll, setCardListAll, setOpenModalRegister } = useMyContext();
  const [searchParams] = useSearchParams();
  const isOpenModalRegister = searchParams.get("register") === "true";
  useEffect(() => {
    setOpenModalRegister(isOpenModalRegister);
  }, [isOpenModalRegister]);
  useEffect(() => {
    cardServices
      .getCardList()
      .then((res) => {
        console.log(res);
        setCardList(res.data.result.data.credit_card_list);
        setCardListAll(res.data.result.data.credit_card_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="">
      <Banner />
      <div
        className="pt-[20px] lg:pt-[33px] mb-[20px] lg:mb-[40px]"
        id="card-link"
      >
        <h3 className="text-center text-[16px] sm:text-[22px] md:text-[28] lg:text-[36px] font-bold leading-normal mb-[0px] sm:mb-[10px] lg:mb-[24px] text-white">
          Các dòng thẻ tín dụng
        </h3>
        <Card cardList={cardList} />

        <div className="pt-[10px] md:pt-[40px]" id="utilities-link">
          <h3 className="text-center text-[16px] sm:text-[22px] md:text-[28] md:text-[36px] font-bold leading-normal mb-[24px] text-white">
            Một số tiện ích khi mở thẻ
          </h3>
          <div className="flex flex-col items-center sm:flex-row">
            <div className="w-[100%] sm:w-[50%] flex items-center justify-center">
              <img src={creditCart} alt="card" />
            </div>
            <div className="w-[100%] sm:w-[50%]">
              {benefits.map((benefit, i) => (
                <div key={i} className="mb-[32px]">
                  <h5 className="text-[14px] font-semibold uppercase text-white">
                    {benefit.title}
                  </h5>
                  <ul
                    className="font-normal ml-[20px] mt-2"
                    style={{ listStyle: "disc" }}
                  >
                    {benefit.data.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
              <p className="font-semibold leading-6">
                Và nhiều lợi ích khác đang chờ bạn...
              </p>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col sm:flex-row mt-[56px]">
          <div
            className="rounded-xl px-[40px] py-[32px] w-full sm:w-[90%]"
            style={{
              backdropFilter: "blur(6.479682922363281px)",
              background: "rgba(60, 179, 113, 0.17)",
            }}
            id="form-link"
          >
            <FormRegister />
          </div>
          <div className="sm:w-[140px]"></div>
          <div
            className="absolute bottom-[-45px] sm:bottom-auto sm:top-[50%] left-[30%] sm:left-auto sm:right-0"
            style={{
              transform: "translateY(-50%)",
            }}
          >
            <div className="relative">
              <div className="w-[150px] sm:w-[170px] lg:w-[280px] h-[150px] sm:h-[170px] lg:h-[280px] rounded-full bg-[#D9D9D9] overflow-hidden">
                <img src={personImg} alt="person" />
              </div>
              <div className="absolute top-[-40px] left-[-60px] mt-[42px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="123"
                  height="97"
                  viewBox="0 0 123 97"
                  fill="none"
                >
                  <path
                    d="M39.1934 0C17.5475 0 0 17.5475 0 39.1934C0 60.8393 17.5474 78.3867 39.1933 78.3867H76.9408C80.6388 78.3867 84.2172 77.8746 87.6089 76.9174L119.37 95.7532C120.84 96.6249 122.555 95.0779 121.839 93.5264L107.872 63.2668C113.05 56.6236 116.134 48.2687 116.134 39.1934C116.134 17.5475 98.5868 0 76.9409 0H39.1934Z"
                    fill="#EFC342"
                  />
                  <ellipse
                    cx="34.7782"
                    cy="40.0668"
                    rx="7.84289"
                    ry="7.934"
                    fill="white"
                  />
                  <ellipse
                    cx="57.4354"
                    cy="40.0668"
                    rx="7.84289"
                    ry="7.934"
                    fill="white"
                  />
                  <ellipse
                    cx="80.0926"
                    cy="40.0668"
                    rx="7.84289"
                    ry="7.934"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="absolute bottom-[-18px] right-[10px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="83"
                  height="94"
                  viewBox="0 0 83 94"
                  fill="none"
                >
                  <path
                    d="M40.6003 0V46.6653M40.6003 93.3307V46.6653M40.6003 46.6653L4 23.7902M40.6003 46.6653L79.0305 69.5405M40.6003 46.6653L4 69.5405M40.6003 46.6653L79.0305 23.7902"
                    stroke="#3CB371"
                    strokeWidth="15.0061"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
