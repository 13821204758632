import React from "react";
import { useState, createContext } from "react";

const DataContext = createContext();

const ContextProvider = ({ children }) => {
  const [theme, setTheme] = useState();
  const [isOpenModalRegister, setOpenModalRegister] = useState(false);
  const [isOpenModalSelectCard, setOpenModalSelectCard] = useState(false);
  const [isOpenModalSelectCardCompare, setOpenModalSelectCardCompare] =
    useState(false);
  const [isOpenModalCompareTab, setOpenModalCompareTab] = useState(false);
  const [listCompare, setListCompare] = useState([]);
  const [isOpenMenuMobile, setOpenMenuMobile] = useState(false);
  const [cardSelect, setCardSelect] = useState(0);
  const [cardIndexDelete, setIndexCardDelete] = useState(null);
  const [cardListAll, setCardListAll] = useState({});

  const store = {
    theme,
    setTheme,
    isOpenModalRegister,
    setOpenModalRegister,
    setOpenModalSelectCard,
    isOpenModalSelectCard,
    isOpenModalSelectCardCompare,
    setOpenModalSelectCardCompare,
    isOpenModalCompareTab,
    setOpenModalCompareTab,
    listCompare,
    setListCompare,
    isOpenMenuMobile,
    setOpenMenuMobile,
    cardSelect,
    setCardSelect,
    cardIndexDelete,
    setIndexCardDelete,
    cardListAll,
    setCardListAll,
  };

  return <DataContext.Provider value={store}>{children}</DataContext.Provider>;
};

export { ContextProvider, DataContext };
