import React from 'react'
import { Link } from 'react-router-dom'

export default function Previous() {
    return (
        <div className='w-full py-4  mt-2'>
            <Link to='/' className='flex items-center w-fit' >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14.6668 8H1.3335" stroke="#3CB371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.00016 12.6667L1.3335 8.00001L6.00016 3.33334" stroke="#3CB371" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='text-[13px] font-semibold text-[#3CB371] ml-1'>Quay lại</p>
            </Link>
        </div>
    )
}
