import { Field, Formik, Form } from "formik";
import React from "react";
import InputField from "./InputField";
import Button from "./Button";
import Contact from "./Contact";
import * as Yup from "yup";
import { cardServices } from "../services/cardService";
import { toast } from "react-toastify";

const schema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập trường này"),
  id_card: Yup.string()
    .test("is-number", "Vui lòng nhập số", (value) => !isNaN(value))
    .required("Vui lòng nhập trường này"),
  phone: Yup.string()
    .test("is-number", "Vui lòng nhập số", (value) => !isNaN(value))
    .required("Vui lòng nhập trường này"),
  email: Yup.string()
    .email("Email không hợp lệ")
    .required("Vui lòng nhập trường này"),
});
export default function FormRegister() {
  const handleSubmit = (values) => {
    cardServices
      .registerCTV(values)
      .then((res) => {
        console.log(res);
        if (res.data.result.meta.status) {
          toast.success(res.data.result.meta.message);
        } else {
          const keys = Object.keys(res.data.result.meta.message);
          keys.forEach((key, index) => {
            toast.error(res.data.result.meta.message[key]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <h3 className="text-[16px] sm:text-[22px] md:text-[28px] lg:text-[32px] font-bold text-white">
        Đăng ký làm CTV
      </h3>
      <div className="mt-1 flex flex-col lg:flex-row lg:gap-[72px]">
        <div>
          <p className=" font-normal leading-6">
            Hãy tham gia đội ngũ CTV tiềm năng của CreditCardVN ngay bây giờ
          </p>
          <Formik
            validationSchema={schema}
            initialValues={{
              name: "",
              id_card: "",
              email: "",
              phone: "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            <Form className="mt-[16px] flex flex-col gap-3">
              <Field
                name="name"
                placeholder="Họ và tên"
                className="input-form-register"
                component={InputField}
              />
              <Field
                name="id_card"
                placeholder="Số CMND/CCCD"
                className="input-form-register"
                component={InputField}
              />
              <Field
                name="email"
                placeholder="Email"
                className="input-form-register"
                component={InputField}
              />
              <Field
                name="phone"
                placeholder="Số điện thoại"
                className="input-form-register"
                component={InputField}
              />
              <div className="flex justify-center">
                <Button
                  textSize="16px"
                  className="mt-1 w-[180px]"
                  type="submit"
                >
                  Đăng ký
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
        <Contact />
        <div className="h-[150px] sm:h-0 w-0 sm:w-[140px]"></div>
      </div>
    </>
  );
}
