import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import arrRight from "../assets/images/arrow-right.png";
import { listCard } from "../services/dataFake";
import useMyContext from "../hooks/useMyContext";
import CardDescription from "./CardDescription";

export default function Card({ cardList }) {
  const {
    setOpenModalRegister,
    setOpenModalCompareTab,
    isOpenModalCompareTab,
    setListCompare,
    listCompare,
    setCardSelect,
  } = useMyContext();

  const handleCompareChange = (e) => {
    if (e.target.checked && listCompare.length < 3) {
      const result = cardList.filter((c) => c.be_id == e.target.value)[0];
      setListCompare((prevState) => [...prevState, result]);
    } else {
      const result = listCompare.filter((c) => c.be_id != e.target.value);
      setListCompare(result);
    }
  };
  const handleChecked = (id) => {
    for (let i = 0; i < listCompare.length; i++) {
      if (listCompare[i].be_id == id) {
        return true;
      }
    }
    return false;
  };

  const handleRegister = (id) => {
    setOpenModalRegister(true);
    setCardSelect(id);
  };

  useEffect(() => {
    if (listCompare.length > 0 && !isOpenModalCompareTab) {
      setOpenModalCompareTab(true);
    } else if (listCompare.length === 0) {
      setOpenModalCompareTab(false);
    }
  }, [listCompare]);

  return (
    <>
      {cardList.map((item, index) => (
        <div
          key={item.be_id}
          className="mb-[10px] md:mb-[16px] p-[10px] md:p-[24px]  rounded-xl relative"
          style={{
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
            background: "rgba(39, 50, 47, 0.24)",
            border: "1px solid #3CB371",
          }}
        >
          <h4 className="text-[#3cb371] text-[14px] sm:text-[18px] lg:text-[24px] font-bold leading-6">
            {item.title}
          </h4>

          <div className="flex flex-col-reverse sm:flex-row card-content">
            <div>
              <div className="flex gap-[20px] md:gap-[42px] flex-col sm:flex-row">
                <div className="text-[14px] font-semibold leading-6 mt-[5px] md:mt-[16px] flex-grow w-full sm:w-[33%]">
                  <div>
                    <h6 className="text-white">TÍNH NĂNG NỔI BẬT</h6>
                    <CardDescription data={item.special_features} />
                  </div>
                  <div className="sm:mt-[16px]">
                    <h6 className="text-white">HẠN MỨC VÀ PHÍ</h6>
                    <CardDescription data={item.limit} />
                    <CardDescription data={item.annual_fee} />
                  </div>
                </div>
                <div className="text-[14px] font-semibold leading-6 sm:mt-[16px] flex-grow w-full sm:w-[33%]">
                  <h6 className="text-white">LỢI ÍCH</h6>
                  <CardDescription data={item.promotion.inuse_promotion} />
                </div>
                <div className="text-[14px] font-semibold leading-6 sm:mt-[16px] flex-grow w-full sm:w-[33%]">
                  <h6 className="text-white">HỒ SƠ</h6>
                  <CardDescription data={item.application} />
                </div>
              </div>
            </div>
            <div
              className="card-img sm:ml-6 sm:pl-6 min-w-[190px] mt-8 lg:mt-0 text-center !border-l-0 lg:!border-l"
              style={{ borderLeft: "1px solid rgba(171, 171, 171, 0.20)" }}
            >
              <div
                className={`card-flip card-flip-${index}`}
                onClick={() => {
                  const card = document.querySelector(`.card-flip-${index}`);
                  card.classList.toggle("is-flipped");
                }}
              >
                <div class="card__face card__face--front">
                  <div className="flex justify-center items-center">
                    <img src={item.front_image} alt="card" />
                  </div>
                </div>
                <div class="card__face card__face--back">
                  <div className="flex justify-center items-center">
                    <img src={item.back_image} alt="card" />
                  </div>
                </div>

                <div className="flex justify-center items-center invisible">
                  <img src={item.front_image} alt="card" />
                </div>
              </div>
              <div>
                <div className="flex justify-center items-center">
                  <Button
                    onClick={() => handleRegister(item.id)}
                    className="mt-[16px]"
                  >
                    Đăng ký ngay
                  </Button>
                </div>
                <Link
                  to={`/detail/${item.be_id}`}
                  className="mt-2 text-[13px] font-semibold leading-6 text-[#3CB371] flex justify-center items-center hover:opacity-80"
                >
                  Xem chi tiết
                  <img src={arrRight} alt="icon" />
                </Link>
                <div className="flex justify-center items-center mt-3">
                  <input
                    className="w-4 h-4 "
                    type="checkbox"
                    id={`compare${item.be_id}`}
                    name={`compare${item.be_id}`}
                    value={item.be_id}
                    onChange={handleCompareChange}
                    checked={handleChecked(item.be_id)}
                  />
                  <label className="ml-1" htmlFor={`compare${item.be_id}`}>
                    So sánh thẻ
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
