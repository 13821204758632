import React from 'react'
import banner from '../assets/images/banner.png'
import arrow from '../assets/images/arrow.png'
import vector from '../assets/images/vector.png'
import Button from './Button'
import useMyContext from '../hooks/useMyContext'

export default function Slider() {
    const { setOpenModalRegister, } = useMyContext()

    return (
        <div className='relative'>
            <img src={banner} alt='banner' className='w-full min-h-[120px] sm:min-h-[200px] md:min-h-[250px] lg:min-h-[350px]' />
            <div className='absolute left-[48px] top-2 sm:top-[8px] md:top-[13px] lg:top-[63px]'>
                <div className=''>
                    <span className='text-white sm:text-[25px] md:text-[30px] lg:text-[48px] font-bold sm:leading-[34px] md:leading-[44px] lg:leading-[64px] tracking-[-1.2px] relative'>
                        Không ngừng mua sắm
                        <br />
                        cùng thẻ tín dụng
                        <img src={arrow} alt='arrow' className='w-10 sm:w-[80px] right-[-45px] absolute sm:right-[-90px] bottom-[-5px] ' />
                        <br />
                        cashback 7.9%
                        <img src={vector} alt='vector' className='absolute left-[10px] bottom-[-10px] sm:bottom-[-20px]' />
                    </span>
                </div>
                <Button onClick={() => setOpenModalRegister(true)} textSize='16px' className='mt-[10px] sm:mt-[30px] lg:mt-[31px] w-[80px] sm:w-auto h-6 sm:h-auto' >Đăng ký ngay</Button>
            </div>
        </div>
    )
}
