import React, { useState } from "react";
import { listCard } from "../../services/dataFake";
import Button from "../Button";
import useMyContext from "../../hooks/useMyContext";
import CloseIcon from "@mui/icons-material/Close";

export default function CompareCard({ data, index, handleDeleteCardCompare }) {
  const {
    setOpenModalRegister,
    setOpenModalSelectCardCompare,
    setIndexCardDelete,
    setCardSelect,
    listCompare,
  } = useMyContext();

  const handleCompareChange = (index = null) => {
    if (index != null) {
      setIndexCardDelete(index);
    } else {
      setIndexCardDelete(null);
    }
    setOpenModalSelectCardCompare(true);
  };

  const handleRegister = () => {
    setOpenModalRegister(true);
    setCardSelect(data.be_id);
  };

  return (
    <div className="py-4">
      {data ? (
        <>
          <div className="relative">
            <div
              className={`card-flip card-flip-${index}`}
              onClick={() => {
                const card = document.querySelector(`.card-flip-${index}`);
                card.classList.toggle("is-flipped");
              }}
            >
              <div class="card__face card__face--front">
                <div className="flex justify-center items-center">
                  <img src={data.front_image} alt="card" />
                </div>
              </div>
              <div class="card__face card__face--back">
                <div className="flex justify-center items-center">
                  <img src={data.back_image} alt="card" />
                </div>
              </div>

              <div className="flex justify-center items-center invisible">
                <img src={data.front_image} alt="card" />
              </div>
            </div>
            {listCompare.length > 2 && (
              <div
                className="flex justify-center items-center mt-2 absolute right-[28%] -top-[17%]"
                onClick={() => handleDeleteCardCompare(index, listCompare)}
              >
                <CloseIcon className="cursor-pointer" />
              </div>
            )}
          </div>

          <div className="mt-2">
            <h5 className="text-[20px] text-white font-semibold">
              {data.name}
            </h5>
          </div>
          <div className="flex justify-center mt-2">
            <Button
              onClick={handleRegister}
              padding="6px 12px"
              className="w-[140px] h-[36px]"
            >
              Đăng ký ngay
            </Button>
          </div>
          <h6
            className="text-[#3CB371] mt-3 underline font-semibold text-[14px] cursor-pointer"
            onClick={() => handleCompareChange(index)}
          >
            Chọn thẻ khác để so sánh
          </h6>
        </>
      ) : (
        <div className="flex justify-center w-[140px] sm:w-auto">
          <div
            className="w-full max-w-[244px] h-[88px] sm:h-[145px] flex justify-center items-center"
            style={{
              border: "1px dashed #3CB371",
            }}
          >
            <h5
              onClick={() => handleCompareChange(null)}
              className="text-[#3CB371] !text-[12px] sm:!text-[15px] font-semibold cursor-pointer"
            >
              + Thêm thẻ so sánh
            </h5>
          </div>
        </div>
      )}
    </div>
  );
}
