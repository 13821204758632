import superCard from "../assets/images/supercard.png";
import cardCash from "../assets/images/card-cash-black.png";
import cardFamily from "../assets/images/card-family-link.png";
import cardFinancial from "../assets/images/card-financial.png";

import cardLaz from "../assets/images/card-laz.png";
import cardOnlinePlus from "../assets/images/card-online-plus.png";
import cardPremier from "../assets/images/card-premier.png";

import cardRewards from "../assets/images/card-rewards.png";
import cardTravel from "../assets/images/card-travel.png";

export const listCard = [
  {
    id: "1",
    title: "VIB Super Card",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: superCard,
    promotion: [
      "Nhận số Điểm thưởng tích lũy theo giá trị giao dịch chi tiêu thuộc 1 trong 6 nhóm Danh Mục Chi Tiêu được đăng ký như sau:",
      [
        "Ẩm thực: 15%;",
        "Du lịch: 15%;",
        "Mua sắm: 15%;",
        "Ẩm thực/Du lịch/Mua sắm: 10%;",
        "Giao dịch trực tuyến: 10%;",
        "Giao dịch nước ngoài: 10%",
      ],
      "Nhận số Điểm thưởng tích lũy 0.1% cho các giao dịch chi tiêu còn lại không thuộc Nhóm Danh Mục Chi Tiêu đã đăng ký",
      "Điểm thưởng tích lũy tối đa/kỳ sao kê: 1.000.000 Điểm thưởng",
    ],
    cardLimit: "900 triệu đồng",
    time: "55 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "999.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "4%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "2",
    title: "VIB Online Plus 2in1",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardOnlinePlus,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "800 triệu đồng",
    time: "56 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "9199.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "4%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "3",
    title: "VIB Family Link",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardFamily,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "700 triệu đồng",
    time: "26 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "9299.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "6%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "4",
    title: "VIB Travel Élite",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardTravel,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "600 triệu đồng",
    time: "156 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "299.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "2%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "5",
    title: "VIB Cash Back",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardCash,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "500 triệu đồng",
    time: "66 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "399.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "3%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "6",
    title: "VIB Rewards Unlimited",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardRewards,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "400 triệu đồng",
    time: "54 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "499.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "4%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "7",
    title: "VIB Financial Free",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardFinancial,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "300 triệu đồng",
    time: "55 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "599.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "5%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "8",
    title: "VIB Premier Boundless",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardPremier,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "200 triệu đồng",
    time: "57 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "799.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "7%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
  {
    id: "9",
    title: "VIB LazCard",
    features: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
    levels: ["Hạn mức lên đến 600 triệu đồng", "Phí thường niên 999.000 VNĐ"],
    benefits: [
      "Tặng ngày 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các đơn vị chấp nhận thẻ trực tiếp.",
      "Nhận tích điểm hoàn tiền lên đến 6% cho chi tiêu trực tuyến tại nước ngoài.",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước.",
    ],
    profiles: [
      "Bản sao CMND/CCCD/Hộ chiếu và bản sao Hộ khẩu",
      "Bản sao hợp đồng lao động/Quyết định bổ nhiệm và bản gốc Sao kê lương",
    ],
    img: cardLaz,
    promotion: [
      "Tặng ngay 300.000 đồng cho các giao dịch chi tiêu có lưu thông tin thẻ trên ứng dụng/website tại các Đơn vị chấp nhận thẻ trực tuyến",
      "Nhận tích điểm hoàn tiền lên đến 6%",
      "Nhận tích điểm hoàn tiền 6% cho chi tiêu trực tuyến tại nước ngoài",
      "Nhận tích điểm hoàn tiền 3% cho chi tiêu trực tuyến trong nước",
      "Nhận tích điểm hoàn tiền 0.1% cho các chi tiêu khác",
      "Số tiền hoàn tối đa: 600.000 điểm thưởng/kỳ sao kê",
    ],
    cardLimit: "100 triệu đồng",
    time: "58 ngày",
    highlights: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    fee: "899.000 VNĐ",
    interests: [
      "Hoàn linh hoạt đến 15% cho chi tiêu Mua sắm, Du lịch, Ăn uống",
      "Tùy chọn Tỉ lệ thanh toán tối thiểu và Ngày sao kê",
    ],
    otherFee:
      "8%/số tiền chậm thanh toán(tối thiểu: 200.000 VNĐ, tối đa: 2.000.000 VNĐ)",
  },
];

export const benefits = [
  {
    title: "Lợi ích khi mở thẻ",
    data: [
      "Hoàn phí thường niên năm đầu dành cho chủ thẻ tín dụng mở mới khi thỏa điều kiện chi tiêu từ 5.000.000 VNĐ từ 01/01/2024 đến 31/03/2024",
      "Hoàn tiền 10% tổng giá trị chi tiêu hợp lệ hàng tháng, tối đa 500.000 VNĐ/ khách hàng/ 30 ngày và 1.500.000 VNĐ/ khách hàng/ 90 ngày",
    ],
  },
  {
    title: "Lợi ích khi sử dụng thẻ",
    data: [
      "Nhận số Điểm thưởng tích lũy theo giá trị giao dịch chi tiêu",
      "Nhận số Điểm thưởng tích lũy 0.1% cho các giao dịch chi tiêu còn lại không thuộc Nhóm Danh Mục Chi Tiêu đã đăng ký",
      "Điểm thưởng tích lũy tối đa/kỳ sao kê: 1.000.000 Điểm thưởng",
    ],
  },
  {
    title: "Lợi ích cộng thêm",
    data: [
      "Chọn và thay đổi tùy chọn các Tỉ lệ thanh toán tối thiểu hàng tháng: 1%, 3%, 5%, 10%, 15%, 20% qua MyVIB 2.0",
      "Chọn và thay đổi tùy chọn các Ngày sao kê hàng tháng: Ngày 5, 10, 15, 20, 25, hoặc Ngày cuối tháng qua MyVIB 2.0",
      "Miễn phí chọn Số Thẻ đẹp",
    ],
  },
];
