import React from 'react'
import Header from './Header'
import Footer from './Footer'
import RegisterModal from '../modals/RegisterModal'
import useMyContext from '../../hooks/useMyContext'
import SelectCardModal from '../modals/SelectCardModal'
import CompareTab from '../compare/CompareTab'

export default function Layout({ children }) {
    const { isOpenModalRegister, isOpenModalSelectCard, isOpenModalCompareTab } = useMyContext()
    return (
        <>
            <div className='app px-2 sm:px-[30px]'>
                <div className='container'>
                    <Header />
                    {children}
                    <Footer />
                    {isOpenModalRegister && <RegisterModal />}
                    {isOpenModalSelectCard && <SelectCardModal />}
                </div>
            </div>
            {isOpenModalCompareTab && <CompareTab />}
        </>
    )
}
