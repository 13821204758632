import React, { useCallback, useEffect, useState } from 'react';

function InputField(props) {
  const { field, form, label, placeholder, className } = props;
  const { name } = field;

  //validation 
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];

  return (
    <div>
      {label && <label className='font-semibold leading-6'>{label}</label>}
      <input
        id={name}
        className={className}
        style={{ background: 'rgba(255, 255, 255, 0.10)' }}
        // rgba(255, 255, 255, 0.40)
        {...field}
        type='text'
        placeholder={placeholder}
      />
      {showError && <p className={`text-[#f44336] m-0 text-sm mt-[3px] text-left font-semibold`}>{errors[name]}</p>}
    </div>
  );
}

export default InputField;