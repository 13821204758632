import React, { useEffect } from "react";

export default function SelectField(props) {
  const {
    field,
    form,
    label,
    className,
    listOption = [],
    setvalue,
    value,
    id,
  } = props;
  const { name } = field;

  //validation
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];
  // useEffect(() => {
  //   if (id) {
  //     let event = document.getElementById(id);
  //     let newValue;
  //     event.addEventListener("change", (e) => {
  //       newValue = e.target.value;
  //       setvalue(e.target.value, value);
  //     });
  //     event.value = newValue;
  //   }
  // }, [id]);
  useEffect(() => {
    setvalue && setvalue(field.value, value);
  }, [field]);

  return (
    <div>
      {label && <label className="font-semibold leading-6">{label}</label>}
      <select {...field} className={className} id={id}>
        <option value="" className="text-[#1e1e1e]">
          Vui lòng chọn
        </option>
        {listOption.map((item, index) => (
          <option
            key={index}
            value={item.value}
            className="text-[#1e1e1e] block"
          >
            {item.label}
          </option>
        ))}
      </select>
      {showError && (
        <p
          className={`text-[#f44336] m-0 text-sm mt-[3px] text-left font-semibold`}
        >
          {errors[name]}
        </p>
      )}
    </div>
  );
}
